<template>
	<please-select-utility alert-class="ma-3">
		<data-loader
			:watch="{utility}"
			:callback="getCurrentSession"
			:data.sync="selectedSession">
			<template #loading>
				<v-progress-linear indeterminate rounded/>
			</template>
			<template #default>
				<v-alert
					v-if="!selectedSession"
					colored-border border="left" elevation="3"
					type="error" class="ma-3"
				> Няма активна сесия за текущото дружество!
				</v-alert>
				<div v-else-if="session && selectedSession">
					<div class="d-flex justify-start ma-2">

						<data-loader :callback="()=>model.fetch('getOtchetnikList')">
							<template #default="{data,loading}">
								<v-autocomplete
									solo dense
									class="flex-grow-0"
									clearable
									item-value="id"
									:items="data || []" :loading="loading"
									hide-details
									item-text="sort"
									v-model="selectedOtchetnik"
									placeholder="Отчетник"
								>
									<template #item="{ item }">
										<span v-if="item.public_initials">{{item.public_initials}} ({{item.user}})</span>
										<span v-else>{{item.user}}</span>
									</template>
									<template #selection="{ item }">
										<span v-if="item.public_initials">{{item.public_initials}} ({{item.user}})</span>
										<span v-else>{{item.user}}</span>
									</template>
								</v-autocomplete>
							</template>
						</data-loader>

						<data-loader ref="warnings" :watch="utility" :callback="()=>model.fetch('getScheduleWarnings', {utility})">
							<template #loading></template>
							<template #default="{data}">
								<my-dialog v-if="data.empty_otchetnik?.length || data.empty_schedule?.length || data.schedule_conflict?.length"
									max-width="600" title="Проблеми"
								>
									<template #activator="{ on, attrs }">
										<v-btn color="warning" v-bind="attrs" v-on="on" class="ml-2">
											<v-icon left>mdi-alert-outline</v-icon>
											Проблеми
										</v-btn>
									</template>
									<v-container>
										<v-alert text outlined type="warning" icon="mdi-alert-outline" v-if="data.empty_otchetnik?.length > 0">
											Станции за визуален отчет без отчетник:
											<ul>
												<li v-for="item in data.empty_otchetnik" :key="item.station_id">
													{{item.station_N}} - {{item.address}}
												</li>
											</ul>
										</v-alert>
										<v-alert text outlined type="warning" icon="mdi-alert-outline" v-if="data.empty_schedule?.length > 0">
											Станции без график:
											<ul>
												<li v-for="item in data.empty_schedule" :key="item.station_id">
													<span v-if="item.public_initials">{{item.public_initials}} ({{item.user}}):</span>
													<span v-else>{{item.user}}:</span>
													{{item.station_N}} - {{item.address}}
												</li>
											</ul>
										</v-alert>
										<v-alert text outlined type="warning" icon="mdi-alert-outline" v-if="data.schedule_conflict?.length > 0">
											Отчетници с конфликт в графика:
											<ul>
												<li v-for="item in data.schedule_conflict" :key="item.id">
													<span v-if="item.public_initials">{{item.public_initials}} ({{item.user}})</span>
													<span v-else>{{item.user}}</span>
												</li>
											</ul>
										</v-alert>
									</v-container>
								</my-dialog>
							</template>
						</data-loader>

					</div>
					<v-divider/>
					<StationOtchetSchedule
						v-if="otchetnik"
						:imodel="model"
						:utility="selectedUtility"
						:session="selectedSession"
						:otchetnik="selectedOtchetnik"
						@reload="reload"
					/>
				</div>
			</template>
		</data-loader>
	</please-select-utility>
</template>

<script>
import DataLoader from "@/ittijs/components/DataLoader.vue";
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility.vue";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import {Mesechen} from "@/views/otcheti/otchetnik_ui/Model";
import StationOtchetSchedule from "@/views/station_data/StationOtchetSchedule.vue";
import MyDialog from "@/ittijs/components/MyDialog.vue";

const model = ITTIModelProvider.getModel(Mesechen);

export default {
	components: {MyDialog, StationOtchetSchedule, PleaseSelectUtility, DataLoader},
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility: 'selectedUtility',
			session: 'selectedSession',
			otchetnik: 'selectedOtchetnik',
		}),
	],
	props: [
		'utility',
		'session',
		'otchetnik',
	],
	data(){
		return {
			model,
			selectedSession: this.session,
			selectedOtchetnik: null,
		}
	},
	methods: {
		async getCurrentSession() {
			this.selectedSession = await model.fetch('getCurrentSession', {utility: this.utility});
		},
		reload(){
			this.$refs.warnings.reload();
		},
	},
}
</script>